<template>
  <div
    v-if="
      ($store.state.fastPermissoes.administrador == 'S' || $store.state.fastPermissoes.tesouraria == 'S') &&
        $store.state.fastPermissoes.ativo == 'S' &&
        $store.state.fastPlataformaModulos.modulo_secretaria
    "
  >
    <div class="fd-app-wrap">
      <!-- #region breadcrumb -->
      <div class="container">        
        <nav>
          <ul class="fd-app-breadcrumb">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                class="aluno_font_color"
                @click.prevent="$router.push('/minhas-plataformas')"
              >Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                href="#"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
              >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                href="#"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/tesouraria')"
              >Modo tesouraria</a>              
            </li>
            <li class="fd-app-breadcrumb-item active">
              <a
                class="aluno_font_color"
                href="#"
              >Comissões</a>
            </li>
          </ul>
        </nav>
      </div>
      <!-- #endregion -->

      <div class="fd-mode-adm-content mt-4">
        <div class="container">
          <div class="row">
            <div class="col-sm-12 col-md-12 fd-mode-adm-content-body col-lg-12 ml-0">
              <section class="fd-app-welcome text-left">
                <h1 class="aluno_font_color">
                  Comissões da
                  <span>plataforma</span>
                </h1>
                <img
                  :src="require('@/assets/images/form-effect-01.png')"
                  style="max-height: 35px"
                >
              </section>
            </div>
          </div>
        </div>

        <!-- #region Conteúdo principal -->
        <section class="container mt-4">    
          <b-tabs>
            <!-- Itens -->
            <b-tab active @click="alteraFastNavegacao('exibirComissaoSchemas')">
              <template #title>
                Schema de comissões
              </template>
              <div class="row">

                <transition name="slide-fade">
                  <div v-if="fastNavegacao.exibirComissaoSchemas" class="col-12">
                    <div class="card mb-4">
                      <div class="card-body">
                        <div class="row">
                          <div class="col-sm-12 col-md-12 col-lg-12 text-right">
                            <button
                              class="btn btn-sm btn-primary"
                              @click.prevent="exibeModalNovaComissaoSchema()"
                            >
                              <small>+ Novo schema de comissão</small>
                            </button>
                          </div>
                          <div class="col-12 table-responsive mt-2">
                            <table class="table table-sm">
                              <thead class="thead-dark">
                                <tr class="text-center">
                                  <th>
                                    <small
                                      class="font-weight-bold"
                                    >Schema de comissão</small>
                                  </th>
                                  <th>
                                    <small
                                      class="font-weight-bold"
                                    >Split</small>
                                  </th>  
                                  <th>
                                    <small
                                      class="font-weight-bold"
                                    >Todas plataformas</small>
                                  </th>   
                                  <th>
                                    <small
                                      class="font-weight-bold"
                                    >Ativo</small>
                                  </th>                                  
                                  <th class="text-center">
                                    <small class="font-weight-bold">Ações</small>
                                  </th>
                                </tr>
                              </thead>
                              <tbody v-if="fastComissaoSchema.loading">
                                <tr>
                                  <td
                                    colspan="5"
                                    class="text-center"
                                  >
                                  Carregando...
                                  </td>
                                </tr>
                              </tbody>
                              <tbody v-else-if="fastComissaoSchema.total.length">
                                <tr
                                  v-for="e in fastComissaoSchema.total"
                                  :key="e.id_comissao_schema"
                                >
                                  <td class="align-middle text-center">
                                    {{ e.nome_schema_comissao }}
                                  </td>   
                                  <td class="align-middle text-center">
                                    {{ e.split }}
                                  </td> 
                                  <td class="align-middle text-center">
                                    <div v-if="e.todas_plataformas">
                                      Sim
                                    </div>
                                    <div v-else>
                                      Não
                                    </div>
                                  </td>    
                                  <td class="align-middle text-center">
                                    <div v-if="!e.desativado">
                                      <span class="text-success">Sim</span>                                      
                                    </div>
                                    <div v-else>
                                      <span class="text-danger">Não</span>
                                    </div>
                                  </td>                
                                  <td class="text-center align-middle">                                  
                                    <button
                                      class="btn btn-sm btn-primary pt-0 pb-0 mr-2"
                                      @click="exibeModaleditarComissaoSchema(e)"
                                    >
                                      <small>Editar</small>
                                    </button>
                                    <button
                                      v-if="!e.qtde_pessoas"
                                      class="btn btn-sm btn-danger pt-0 pb-0 mr-2"
                                      @click="exibeModalExcluirFuncao(e)"
                                    >
                                      <small>Excluir</small>
                                    </button>
                                    <button
                                      v-else
                                      class="btn btn-sm btn-secondary pt-0 pb-0 mr-2"
                                      disabled
                                    >
                                      <small>Excluir</small>
                                    </button>

                                  </td>
                                </tr>
                              </tbody>
                              <tbody v-else>
                                <tr>
                                  <td
                                    colspan="5"
                                    class="text-center"
                                  >
                                    Nenhum schema de comissão cadastrado
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>              
                  </div>
                </transition>

                <transition name="slide-fade">
                  <div v-if="fastNavegacao.novaComissaoSchema" class="col-12">
                    <div class="card mb-4">
                      <div class="card-header py-3">
                        <div class="row">
                          <div class="col-sm-12 col-md-12 col-lg-12">
                            <h6 class="m-0 font-weight-bold text-primary">
                              Novo schema de comissão
                            </h6>
                          </div>
                        </div>
                      </div>
                      <div class="card-body">
                        <div class="row">
                          <div class="col-12">
                            <div class="row">
                              <div class="col-sm-12 col-md-12 col-lg-5">
                                <label>Nome do esquema</label>
                                <input v-model="fastComissaoSchemaNovo.nome_schema_comissao" :class="fastComissaoSchemaNovo.nome_schema_comissao ? 'form-control' : 'form-control border border-danger'"/>
                              </div>
                              <div class="col-sm-12 col-md-12 col-lg-3">
                                <div class="fd-app-curso-aulas mt-0">
                                  <div class="fd-app-curso-aulas-list mt-0">
                                    <div class="fd-app-curso-aulas-list-item shadow-none border-0 row p-0">
                                      <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                                        <div class="col-12 text-center mb-1">
                                          <span>Todas as plataformas</span>
                                        </div>
                                        <div class="col-12 text-center">
                                          <label class="switch">
                                            <input   
                                              v-model="fastComissaoSchemaNovo.todas_plataformas"                                        
                                              type="checkbox"                                           
                                            >
                                            <span class="slider round" />
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div v-if="fastComissaoSchemaNovo.nome_schema_comissao" class="col-sm-12 col-md-12 col-lg-12 mt-4 mb-4">
                                <div class="row">
                                  <div class="col-sm-12 col-md-12 col-lg-6">
                                    <label>
                                      Itens da comissão
                                    </label>     
                                  </div>                                    
                                  <div class="col-sm-12 col-md-12 col-lg-6 text-right">
                                    <button class="btn btn-sm btn-primary" @click="exibeModalCriarComissaoDeterminacao()">
                                      <small>+ Novo item</small>                                      
                                    </button>
                                  </div>
                                </div>
                                <div class="row">
                                  <div v-for="(e, index) in fastComissaoSchemaNovo.determinacoes" :key="index" class="col-12 table-responsive mt-4">
                                    <div class="row">
                                      <div class="col-sm-12 col-md-12 col-lg-6">
                                        <h6>Item {{index+1}}</h6>
                                      </div>
                                      <div class="col-sm-12 col-md-12 col-lg-6 text-right">
                                        <button class="btn btn-sm btn-danger pt-0 pb-0" @click="removerItemComissaoNovo(index)">
                                          <small>- Remover</small>
                                        </button> 
                                      </div>
                                    </div>
                                    <table class="table table-sm table-bordered">
                                      <thead class="thead-dark">
                                        <tr class="text-center">
                                          <th>
                                            <small
                                              class="font-weight-bold"
                                            >Tipo determinação</small>
                                          </th>
                                          <th>
                                            <small
                                              class="font-weight-bold"
                                            ></small>
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>                                          
                                        <tr v-if="e.id_pessoa">
                                          <td class="align-middle text-center">
                                            Pessoa
                                          </td> 
                                          <td class="align-middle text-center">
                                            {{e.nome_razao + ' ' + e.sobrenome_fantasia + ' (' + e.email + ')'}}
                                          </td>  
                                        </tr>
                                        <tr v-if="e.id_funcao_pessoa">
                                          <td class="align-middle text-center">
                                            Função pessoa
                                          </td> 
                                          <td class="align-middle text-center">
                                            {{e.nome_funcao_pessoa}}
                                          </td>  
                                        </tr>
                                        <tr v-if="e.id_curso">
                                          <td class="align-middle text-center">
                                            Curso livre
                                          </td> 
                                          <td class="align-middle text-center">
                                            {{e.nome_curso}}
                                          </td>  
                                        </tr>
                                        <tr v-if="e.id_secretaria_curso">
                                          <td class="align-middle text-center">
                                            Curso secretaria
                                          </td> 
                                          <td class="align-middle text-center">
                                            {{e.nome_curso_secretaria}}
                                          </td>  
                                        </tr>
                                        <tr v-if="e.id_secretaria_curso_programa">
                                          <td class="align-middle text-center">
                                            Módulo
                                          </td> 
                                          <td class="align-middle text-center">
                                            {{e.sigla_programa}}
                                          </td>  
                                        </tr>
                                        <tr v-if="e.id_secretaria_curso_programa_unidade">
                                          <td class="align-middle text-center">
                                            Unidade curricular
                                          </td> 
                                          <td class="align-middle text-center">
                                            {{e.nome_curso_unidade}}
                                          </td>  
                                        </tr>
                                        <tr v-if="e.percentual && e.percentual > 0">
                                          <td class="align-middle text-center">
                                            Percentual
                                          </td>
                                          <td class="align-middle text-center">
                                            {{e.percentual}}%
                                          </td>
                                        </tr>
                                        <tr v-else> 
                                          <td class="align-middle text-center">
                                            Valor
                                          </td> 
                                          <td class="align-middle text-center">
                                            R$ {{formataPreco(e.valor)}}
                                          </td>
                                        </tr>
                                        <tr>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                  <div v-if="!fastComissaoSchemaNovo.determinacoes.length" class="col-12 mt-4 text-center">
                                    <span>Nenhum item adicionado</span>
                                  </div>
                                </div>

                              </div>
                              <div class="col-sm-12 col-md-12 col-lg-12 mt-4">
                                <button v-if="!fastComissaoSchemaNovo.loading" class="btn btn-sm btn-primary mr-2" @click="crianovaComissaoSchema()">
                                  Criar novo schema
                                </button>
                                <button
                                  v-else
                                  class="btn btn-sm btn-secondary mr-2"
                                  disabled
                                >
                                  <small>Criando...</small>
                                </button>

                                <button class="btn btn-sm btn-secondary mr-2" @click="alteraFastNavegacao('exibirComissaoSchemas')">
                                  Voltar
                                </button>
                              </div>
                            </div>  
                          </div>
                        </div>
                      </div>
                    </div>            
                  </div>
                </transition>

                <transition name="slide-fade">
                  <div v-if="fastNavegacao.editarComissaoSchema" class="col-12">
                    <div class="card mb-4">
                      <div class="card-header py-3">
                        <div class="row">
                          <div class="col-sm-12 col-md-12 col-lg-12">
                            <h6 class="m-0 font-weight-bold text-primary">
                              Editar schema
                            </h6>
                          </div>
                        </div>
                      </div>
                      <div class="card-body">
                        <div class="row">
                          <div class="col-12">
                            <div class="row">
                              <div class="col-sm-12 col-md-12 col-lg-5">
                                <label>Nome do esquema</label>
                                <input v-model="fastComissaoSchemaEditar.nome_schema_comissao" :class="fastComissaoSchemaEditar.nome_schema_comissao ? 'form-control' : 'form-control border border-danger'"/>
                              </div>
                              <div class="col-sm-12 col-md-12 col-lg-3">
                                <div class="fd-app-curso-aulas mt-0">
                                  <div class="fd-app-curso-aulas-list mt-0">
                                    <div class="fd-app-curso-aulas-list-item shadow-none border-0 row p-0">
                                      <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                                        <div class="col-12 text-center mb-1">
                                          <span>Todas as plataformas</span>
                                        </div>
                                        <div class="col-12 text-center">
                                          <label class="switch">
                                            <input   
                                              v-model="fastComissaoSchemaEditar.todas_plataformas"                                        
                                              type="checkbox"                                           
                                            >
                                            <span class="slider round" />
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-12 col-md-12 col-lg-3">
                                <div class="fd-app-curso-aulas mt-0">
                                  <div class="fd-app-curso-aulas-list mt-0">
                                    <div class="fd-app-curso-aulas-list-item shadow-none border-0 row p-0">
                                      <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                                        <div class="col-12 text-center mb-1">
                                          <span>Desativado</span>
                                        </div>
                                        <div class="col-12 text-center">
                                          <label class="switch">
                                            <input   
                                              v-model="fastComissaoSchemaEditar.desativado"                                        
                                              type="checkbox"                                           
                                            >
                                            <span class="slider round" />
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-12 col-md-12 col-lg-12 mt-4 mb-4">
                                <div class="row">
                                  <div class="col-sm-12 col-md-12 col-lg-6">
                                    <label>
                                      Itens da comissão
                                    </label>     
                                  </div>                                    
                                  <div class="col-sm-12 col-md-12 col-lg-6 text-right">
                                    <button class="btn btn-sm btn-primary" @click="exibeModalEditarComissaoDeterminacao()">
                                      <small>+ Novo item</small>                                      
                                    </button>
                                  </div>
                                </div>

                                <div v-if="fastComissaoSchemaEditar.determinacoesLoading" class="row">
                                  <div class="col-12 mt-4 text-center">
                                    <span>Carregando itens...</span>
                                  </div>
                                </div>
                                <div v-else-if="fastComissaoSchemaEditar.determinacoes.length" class="row">
                                  <div v-for="(e, index) in fastComissaoSchemaEditar.determinacoes" :key="index" class="col-12 table-responsive mt-4">
                                    <div class="row">
                                      <div class="col-sm-12 col-md-12 col-lg-6">
                                        <h6>Item {{index+1}}</h6>
                                      </div>
                                      <div class="col-sm-12 col-md-12 col-lg-6 text-right">
                                        <button class="btn btn-sm btn-danger pt-0 pb-0" @click="removerItemComissaoEditar(e)">
                                          <small>- Remover</small>
                                        </button> 
                                      </div>
                                    </div>
                                    <table class="table table-sm table-bordered">
                                      <thead class="thead-dark">
                                        <tr class="text-center">
                                          <th>
                                            <small
                                              class="font-weight-bold"
                                            >Tipo determinação</small>
                                          </th>
                                          <th>
                                            <small
                                              class="font-weight-bold"
                                            ></small>
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>                                          
                                        <tr v-if="e.id_pessoa">
                                          <td class="align-middle text-center">
                                            Pessoa
                                          </td> 
                                          <td class="align-middle text-center">
                                            {{e.nome_razao + ' ' + e.sobrenome_fantasia + ' (' + e.email + ')'}}
                                          </td>  
                                        </tr>
                                        <tr v-if="e.id_funcao_pessoa">
                                          <td class="align-middle text-center">
                                            Função pessoa
                                          </td> 
                                          <td class="align-middle text-center">
                                            {{e.nome_funcao_pessoa}}
                                          </td>  
                                        </tr>
                                        <tr v-if="e.id_curso">
                                          <td class="align-middle text-center">
                                            Curso livre
                                          </td> 
                                          <td class="align-middle text-center">
                                            {{e.nome_curso}}
                                          </td>  
                                        </tr>
                                        <tr v-if="e.id_secretaria_curso">
                                          <td class="align-middle text-center">
                                            Curso secretaria
                                          </td> 
                                          <td class="align-middle text-center">
                                            {{e.nome_curso_secretaria}}
                                          </td>  
                                        </tr>
                                        <tr v-if="e.id_secretaria_curso_programa">
                                          <td class="align-middle text-center">
                                            Módulo
                                          </td> 
                                          <td class="align-middle text-center">
                                            {{e.sigla_programa}}
                                          </td>  
                                        </tr>
                                        <tr v-if="e.id_secretaria_curso_programa_unidade">
                                          <td class="align-middle text-center">
                                            Unidade curricular
                                          </td> 
                                          <td class="align-middle text-center">
                                            {{e.nome_curso_unidade}}
                                          </td>  
                                        </tr>
                                        <tr v-if="e.percentual && e.percentual > 0">
                                          <td class="align-middle text-center">
                                            Percentual
                                          </td>
                                          <td class="align-middle text-center">
                                            {{e.percentual}}%
                                          </td>
                                        </tr>
                                        <tr v-else> 
                                          <td class="align-middle text-center">
                                            Valor
                                          </td> 
                                          <td class="align-middle text-center">
                                            R$ {{formataPreco(e.valor)}}
                                          </td>
                                        </tr>
                                        <tr>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                                <div v-else class="col-12 mt-4 text-center">
                                  <span>Nenhum item adicionado</span>
                                </div>

                              </div>
                              <div class="col-sm-12 col-md-12 col-lg-12 mt-4">
                                <button v-if="!fastComissaoSchemaEditar.loading" class="btn btn-sm btn-primary mr-2" @click="editaComissaoSchema()">
                                  Salvar alterações
                                </button>
                                <button
                                  v-else
                                  class="btn btn-sm btn-secondary mr-2"
                                  disabled
                                >
                                  <small>Salvando...</small>
                                </button>

                                <button class="btn btn-sm btn-secondary mr-2" @click="alteraFastNavegacao('exibirComissaoSchemas')">
                                  Voltar
                                </button>
                              </div>
                            </div>  
                          </div>
                        </div>
                      </div>
                    </div>              
                  </div>
                </transition>

              </div>
            </b-tab>
            <!-- /Itens -->
          </b-tabs>
        </section>
        <!-- #endregion -->

        <div class="container">
          <div class="mt-5 mb-5">
            <a
              id="btn_voltar_modo_admin"
              class="btn btn-red-hollow"
              :href="'/plataforma/' + $route.params.id_plataforma + '/tesouraria'"
              @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/tesouraria')"
            >
              <b-icon-arrow-return-left />
              Voltar para menu
            </a>
          </div>
        </div>
      
      </div>
    </div>

    <!-- #region Modals -->
    <modal
      name="modalExcluirSchemaComissao"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthSm"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-6 pl-4 pr-4">
          <h4>Excluir schema de comissão?</h4>
        </div>
        <div class="col-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalExcluirSchemaComissao')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <p class="text-danger">O schema {{fastComissaoSchemaEditar.nome_schema_comissao}} será excluído permanentemente.</p>
            </div>
            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-danger"
                  @click.prevent="excluiComissaoSchema()"
                >
                  Confirmar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalCriarComissaoDeterminacao"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-10 pl-4 pr-4">
          <h4>Criar item de comissão</h4>
        </div>
        <div class="col-2 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalCriarComissaoDeterminacao')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12 table-responsive mt-2">
              <table class="table table-sm">
                <thead class="thead-dark">
                  <tr class="text-center">
                    <th>
                      <small
                        class="font-weight-bold"
                      >Tipo determinação</small>
                    </th>
                    <th>
                      <small
                        class="font-weight-bold"
                      ></small>
                    </th>
                    <th>
                      <small
                        class="font-weight-bold"
                      >Ações</small>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="align-middle text-center">
                      Pessoa
                    </td> 
                    <td class="align-middle text-center">
                      <input v-if="!fastComissaoSchemaDeterminacaoNovo.id_funcao_pessoa" :value="fastComissaoSchemaDeterminacaoNovo.id_pessoa ? fastComissaoSchemaDeterminacaoNovo.nome_razao + ' ' + fastComissaoSchemaDeterminacaoNovo.sobrenome_fantasia + ' (' + fastComissaoSchemaDeterminacaoNovo.email + ')' : 'Nenhuma pessoa selecionada'" type="text" :class="fastComissaoSchemaDeterminacaoNovo.id_pessoa ? 'form-control' : 'form-control bg-light'" @click="showModal('modalListaPessoas', 'modalCriarComissaoDeterminacao')">
                      <input v-else type="text" value="" class="form-control" readonly/>
                    </td>  
                    <td class="align-middle text-center">
                      <button v-if="fastComissaoSchemaDeterminacaoNovo.id_pessoa" class="btn btn-sm btn-danger" @click="fastComissaoSchemaDeterminacaoNovo.id_pessoa = ''">- Remover</button>
                    </td> 
                  </tr>
                  <tr>
                    <td class="align-middle text-center">
                      Função pessoa
                    </td> 
                    <td class="align-middle text-center">
                      <input v-if="!fastComissaoSchemaDeterminacaoNovo.id_pessoa" :value="fastComissaoSchemaDeterminacaoNovo.id_funcao_pessoa ? fastComissaoSchemaDeterminacaoNovo.nome_funcao_pessoa : 'Nenhuma função selecionada'" type="text" :class="fastComissaoSchemaDeterminacaoNovo.id_funcao_pessoa ? 'form-control' : 'form-control bg-light'" @click="showModal('modalListaFuncoesPessoa', 'modalCriarComissaoDeterminacao')">
                      <input v-else type="text" value="" class="form-control" readonly/>
                    </td>  
                    <td class="align-middle text-center">
                      <button v-if="fastComissaoSchemaDeterminacaoNovo.id_funcao_pessoa" class="btn btn-sm btn-danger" @click="fastComissaoSchemaDeterminacaoNovo.id_funcao_pessoa = ''">- Remover</button>
                    </td>
                  </tr>
                  <tr>
                    <td class="align-middle text-center">
                      Curso livre
                    </td> 
                    <td class="align-middle text-center">
                      <input :value="fastComissaoSchemaDeterminacaoNovo.id_curso ? fastComissaoSchemaDeterminacaoNovo.nome_curso : 'Nenhum curso livre selecionado'" type="text" :class="fastComissaoSchemaDeterminacaoNovo.id_curso ? 'form-control' : 'form-control bg-light'" @click="showModal('modalListaCursosLivres', 'modalCriarComissaoDeterminacao')">
                    </td>  
                    <td class="align-middle text-center">
                      <button v-if="fastComissaoSchemaDeterminacaoNovo.id_curso" class="btn btn-sm btn-danger" @click="fastComissaoSchemaDeterminacaoNovo.id_curso = ''">- Remover</button>
                    </td>
                  </tr>
                  <tr>
                    <td class="align-middle text-center">
                      Curso secretaria
                    </td> 
                    <td class="align-middle text-center">
                      <input :value="fastComissaoSchemaDeterminacaoNovo.id_secretaria_curso ? fastComissaoSchemaDeterminacaoNovo.nome_curso_secretaria : 'Nenhum curso da secretaria selecionado'" type="text" :class="fastComissaoSchemaDeterminacaoNovo.id_secretaria_curso ? 'form-control' : 'form-control bg-light'" @click="showModal('modalListaCursosSecretaria', 'modalCriarComissaoDeterminacao')">
                    </td>  
                    <td class="align-middle text-center">
                      <button v-if="fastComissaoSchemaDeterminacaoNovo.id_secretaria_curso" class="btn btn-sm btn-danger" @click="fastComissaoSchemaDeterminacaoNovo.id_secretaria_curso = ''">- Remover</button>
                    </td>
                  </tr>
                  <tr class="d-none">
                    <td class="align-middle text-center">
                      Módulo
                    </td> 
                    <td class="align-middle text-center">
                      <input :value="fastComissaoSchemaDeterminacaoNovo.id_secretaria_curso_programa ? fastComissaoSchemaDeterminacaoNovo.sigla_programa : 'Nenhum módulo selecionado'" type="text" :class="fastComissaoSchemaDeterminacaoNovo.id_secretaria_curso_programa ? 'form-control' : 'form-control bg-light'" @click="showModal('modalListaCursosSecretariaModulos', 'modalCriarComissaoDeterminacao')">
                    </td>  
                    <td class="align-middle text-center">
                      <button v-if="fastComissaoSchemaDeterminacaoNovo.id_secretaria_curso_programa" class="btn btn-sm btn-danger" @click="fastComissaoSchemaDeterminacaoNovo.id_secretaria_curso_programa = ''">- Remover</button>
                    </td>
                  </tr>
                  <tr class="d-none">
                    <td class="align-middle text-center">
                      Unidade curricular
                    </td> 
                    <td class="align-middle text-center">
                      <input :value="fastComissaoSchemaDeterminacaoNovo.id_secretaria_curso_programa_unidade ? fastComissaoSchemaDeterminacaoNovo.nome_curso_unidade : 'Nenhuma unidade curricular selecionada'" type="text" :class="fastComissaoSchemaDeterminacaoNovo.id_secretaria_curso_programa_unidade ? 'form-control' : 'form-control bg-light'" @click="showModal('modalListaCursosSecretariaUnidades', 'modalCriarComissaoDeterminacao')">
                    </td>  
                    <td class="align-middle text-center">
                      <button v-if="fastComissaoSchemaDeterminacaoNovo.id_secretaria_curso_programa_unidade" class="btn btn-sm btn-danger" @click="fastComissaoSchemaDeterminacaoNovo.id_secretaria_curso_programa_unidade = ''">- Remover</button>
                    </td>
                  </tr>
                  <tr>
                    <td class="align-middle text-center">
                      Percentual
                    </td>
                    <td class="align-middle text-center">
                      <input v-if="!fastComissaoSchemaDeterminacaoNovo.valor || fastComissaoSchemaDeterminacaoNovo.valor == 0" type="number" min="1" step="any" v-model="fastComissaoSchemaDeterminacaoNovo.percentual" class="form-control">
                      <input v-else type="text" value="" class="form-control" readonly/>
                    </td>
                  </tr>
                  <tr class="d-none"> 
                    <td class="align-middle text-center">
                      Valor
                    </td> 
                    <td class="align-middle text-center">
                      <money v-if="!fastComissaoSchemaDeterminacaoNovo.percentual || fastComissaoSchemaDeterminacaoNovo.percentual == 0" type="text" v-model="fastComissaoSchemaDeterminacaoNovo.valor" class="form-control"/>
                      <input v-else type="text" value="" class="form-control" readonly/>
                    </td>
                  </tr>
                  <tr>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>  
          <div class="row mt-4">
            <div class="col-12">
              <button class="btn btn-sm btn-primary" @click="confirmaItemComissao()">+ Confirmar item de comissão</button>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalListaPessoas"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-10 pl-4 pr-4">
          <h4>Lista de pessoas</h4>
        </div>
        <div class="col-2 pl-4 pr-4 text-right">
          <a
            v-if="fastComissaoSchemaDeterminacaoNovo.active"
            class="btn btn-secondary"
            href="#"
            @click.prevent="showModal('modalCriarComissaoDeterminacao', 'modalListaPessoas')"
          >
            <b-icon-arrow-return-left />
          </a>
          <a
            v-else
            class="btn btn-secondary"
            href="#"
            @click.prevent="showModal('modalEditarComissaoDeterminacao', 'modalListaPessoas')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-4 mb-1">
              <input
                type="text"
                class="form-control form-control-sm"
                placeholder="Filtrar por nome ou email"
                v-model="fastPessoasPlataforma.filtroParametros.nome_email.valor"
                @keyup="filtraPessoas()"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4 mb-1">
              <select class="form-control form-control-sm" v-model="fastPessoasPlataforma.filtroParametros.perfil.valor" @change="filtraPessoas">
                <option value="">-- Selecione um perfil --</option>
                <option value="administrador">Administrador</option>
                <option value="aluno">Aluno</option>
                <option value="professor">Professor</option>
                <option value="secretaria">Secretaria</option>
                <option value="tesouraria">Tesouraria</option>
                <option value="responsavel">Responsavel</option>
              </select>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4 text-right d-flex justify-content-end align-items-center">
              <a
                class="text-success fast-cursor-refresh"
                @click.prevent="getUsuariosPlataforma($route.params.id_plataforma)"
              >
                <b-icon-arrow-counterclockwise font-scale="2" />
              </a>
            </div>
            <div class="col-12 table-responsive mt-2">
              <table class="table table-sm">
                <thead class="thead-dark">
                  <tr>
                    <th><small class="font-weight-bold">Nome</small></th>
                    <th><small class="font-weight-bold">Email</small></th>
                    <th class="text-center">
                      <small class="font-weight-bold">Perfil</small>
                    </th>
                    <th class="text-center">
                      <small class="font-weight-bold">Ativo</small>
                    </th>
                    <th class="text-center">
                      <small class="font-weight-bold">Data de cadastro</small>
                    </th>
                    <th class="text-center">
                      <small class="font-weight-bold">Ações</small>
                    </th>
                  </tr>
                </thead>
                <tbody v-if="fastPessoasPlataforma.loading">
                  <tr>
                    <td
                      colspan="6"
                      class="text-center"
                    >
                    <b-icon icon="gear-fill" animation="spin"/> Carregando pessoas 
                    </td>
                  </tr>
                </tbody>
                <tbody v-else-if="fastPessoasPlataforma.paginacao.length">
                  <tr
                    v-for="(e, index) in fastPessoasPlataforma.paginacao"
                    :key="index"
                  >
                    <td class="align-middle">
                      <small>{{ e.nome_razao + " " + e.sobrenome_fantasia }}</small>                                  
                    </td>
                    <td class="align-middle">
                      <small>{{ e.email }}</small>                                  
                    </td>
                    <td class="align-middle text-left">
                      <div
                        v-if="e.administrador == 'S'"
                        class="text-success"
                      >
                        <small><b-icon-check-circle-fill /> Administrador</small>                                      
                      </div>
                      <div
                        v-if="e.professor == 'S'"
                        class="text-success"
                      >
                        <small><b-icon-check-circle-fill /> Professor</small>                                      
                      </div>
                      <div
                        v-if="e.aluno == 'S'"
                        class="text-success"
                      >
                        <small><b-icon-check-circle-fill /> Aluno</small>                                      
                      </div>
                      <div
                        v-if="e.tesouraria == 'S'"
                        class="text-success"
                      >
                        <small><b-icon-check-circle-fill /> Tesouraria</small>                                      
                      </div>
                      <div
                        v-if="e.secretario == 'S'"
                        class="text-success"
                      >
                        <small><b-icon-check-circle-fill /> Secretario</small>                                      
                      </div>
                      <div
                        v-if="e.responsavel == 'S'"
                        class="text-success"
                      >
                        <small><b-icon-check-circle-fill /> Responsavel</small>                                      
                      </div>      
                      <div
                        v-if="e.administrador != 'S' && e.aluno != 'S' && e.professor != 'S' && e.secretaria != 'S' && e.secretario != 'S' && e.responsavel != 'S'"
                        class="text-warning"
                      >
                        <small>Sem perfil</small>                                      
                      </div>                         
                    </td>
                    <td class="align-middle text-center">
                      <small
                        v-if="e.ativo == 'S'"
                        class="badge badge-success mr-2"
                      >sim</small>
                      <small
                        v-else
                        class="badge badge-danger mr-2"
                      >Não</small>                               
                    </td>
                    <td class="align-middle text-center">
                      <small v-if="e.data_cadastro">{{ new Date(e.data_cadastro).toLocaleDateString() }}</small>  
                      <small v-else>Sem registro</small>                              
                    </td>                           
                    <td class="text-center align-middle">       
                      <button
                        class="btn btn-sm btn-success pt-0 pb-0 mr-2"
                        @click="adicionaPessoaComissao(e)"
                      >
                        <small>+ Adicionar</small>
                      </button>  
                      
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td
                      colspan="6"
                      class="text-center"
                    >
                      Nenhuma pessoa encontrada
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-12 text-center mt-4">
              <Pagination
                :page-size="20"
                :items="fastPessoasPlataforma.filtro"
                @changePage="fastPessoasPlataforma.paginacao = $event"
              />
            </div>
          </div> 
        </div>
      </div>
    </modal>

    <modal
      name="modalListaFuncoesPessoa"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-10 pl-4 pr-4">
          <h4>Lista de funções</h4>
        </div>
        <div class="col-2 pl-4 pr-4 text-right">
          <a
            v-if="fastComissaoSchemaDeterminacaoNovo.active"
            class="btn btn-secondary"
            href="#"
            @click.prevent="showModal('modalCriarComissaoDeterminacao', 'modalListaFuncoesPessoa')"
          >
            <b-icon-arrow-return-left />
          </a>
          <a
            v-else
            class="btn btn-secondary"
            href="#"
            @click.prevent="showModal('modalEditarComissaoDeterminacao', 'modalListaFuncoesPessoa')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12 table-responsive mt-2">
              <table class="table table-sm">
                <thead class="thead-dark">
                  <tr class="text-center">
                    <th>
                      <small
                        class="font-weight-bold"
                      >Função</small>
                    </th>
                    <th>
                      <small
                        class="font-weight-bold"
                      >Pessoas</small>
                    </th>
                    <th>
                      <small
                        class="font-weight-bold"
                      >Tipo</small>
                    </th>
                    <th class="text-center">
                      <small class="font-weight-bold">Ações</small>
                    </th>
                  </tr>
                </thead>
                <tbody v-if="fastFuncaoPessoa.loading">
                  <tr>
                    <td
                      colspan="4"
                      class="text-center"
                    >
                    Carregando...
                    </td>
                  </tr>
                </tbody>
                <tbody v-else-if="fastFuncaoPessoa.total.length">
                  <tr
                    v-for="e in fastFuncaoPessoa.total"
                    :key="e.id_funcao_pessoa"
                  >
                    <td class="align-middle text-center">
                      {{ e.nome_funcao_pessoa }}
                    </td>   
                    <td class="align-middle text-center">
                      {{ e.qtde_pessoas }}
                    </td>  
                    <td class="align-middle text-center">
                      <div v-if="e.empregado">
                        Empregado
                      </div>
                      <div v-if="e.vendedor">
                        Vendedor
                      </div>
                      <div v-if="e.secretaria">
                        Secretaria
                      </div>
                      <div v-if="e.parceiro">
                        Parceiro
                      </div>
                      <div v-if="!e.empregado && !e.vendedor && !e.secretaria && !e.parceiro">
                        Personalizado
                      </div>
                    </td>                 
                    <td class="text-center align-middle">                                  
                      <button
                        class="btn btn-sm btn-success pt-0 pb-0 mr-2"
                        @click="adicionaFuncaoPessoaComissao(e)"
                      >
                        <small>+ Adicionar</small>
                      </button>
                      

                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td
                      colspan="4"
                      class="text-center"
                    >
                      Nenhuma função cadastrada
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div> 
        </div>
      </div>
    </modal>

    <modal
      name="modalListaCursosLivres"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-10 pl-4 pr-4">
          <h4>Lista de cursos livres</h4>
        </div>
        <div class="col-2 pl-4 pr-4 text-right">
          <a
            v-if="fastComissaoSchemaDeterminacaoNovo.active"
            class="btn btn-secondary"
            href="#"
            @click.prevent="showModal('modalCriarComissaoDeterminacao', 'modalListaCursosLivres')"
          >
            <b-icon-arrow-return-left />
          </a>
          <a
            v-else
            class="btn btn-secondary"
            href="#"
            @click.prevent="showModal('modalEditarComissaoDeterminacao', 'modalListaCursosLivres')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-4 mb-1">
              <input
                type="text"
                class="form-control form-control-sm"
                placeholder="Filtrar por nome"
                @keyup="filtraCursosLivres"
              >
            </div>
            <div class="col-12 table-responsive mt-2">
              <table class="table table-sm">
                <thead class="thead-dark">
                  <tr class="text-center">
                    <th>
                      <small
                        class="font-weight-bold"
                      >Curso</small>
                    </th>
                    <th>
                      <small
                        class="font-weight-bold"
                      >Publicado</small>
                    </th>
                    <th>
                      <small
                        class="font-weight-bold"
                      >Data disponível</small>
                    </th>
                    <th class="text-center">
                      <small class="font-weight-bold">Ações</small>
                    </th>
                  </tr>
                </thead>
                <tbody v-if="fastCursosLivres.loading">
                  <tr>
                    <td
                      colspan="4"
                      class="text-center"
                    >
                    Carregando...
                    </td>
                  </tr>
                </tbody>
                <tbody v-else-if="fastCursosLivres.filtro.length">
                  <tr
                    v-for="e in fastCursosLivres.paginacao"
                    :key="e.id_curso"
                  >
                    <td class="align-middle">
                      {{ e.nome_curso ? e.nome_curso : e.nome_curso_mae }}
                    </td>   
                    <td class="align-middle text-center">
                      <span v-if="e.publicado" class="badge badge-success">Sim</span>
                      <span v-else class="badge badge-danger">Não</span>
                    </td>  
                    <td class="align-middle text-center">
                      {{ new Date(e.data_disponivel).toLocaleDateString() }}
                    </td>                 
                    <td class="text-center align-middle">                                  
                      <button
                        class="btn btn-sm btn-success pt-0 pb-0 mr-2"
                        @click="adicionaCursoLivreComissao(e)"
                      >
                        <small>+ Adicionar</small>
                      </button>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td
                      colspan="4"
                      class="text-center"
                    >
                      Nenhum curso livre encontrado
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-12 text-center mt-4">
              <Pagination
                :page-size="20"
                :items="fastCursosLivres.filtro"
                @changePage="fastCursosLivres.paginacao = $event"
              />
            </div>
          </div> 
        </div>
      </div>
    </modal>
    
    <modal
      name="modalListaCursosSecretaria"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-10 pl-4 pr-4">
          <h4>Lista de cursos da secretaria</h4>
        </div>
        <div class="col-2 pl-4 pr-4 text-right">
          <a
            v-if="fastComissaoSchemaDeterminacaoNovo.active"
            class="btn btn-secondary"
            href="#"
            @click.prevent="showModal('modalCriarComissaoDeterminacao', 'modalListaCursosSecretaria')"
          >
            <b-icon-arrow-return-left />
          </a>
          <a
            v-else
            class="btn btn-secondary"
            href="#"
            @click.prevent="showModal('modalEditarComissaoDeterminacao', 'modalListaCursosSecretaria')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-4 mb-1">
              <input
                type="text"
                class="form-control form-control-sm"
                placeholder="Filtrar por nome"
                @keyup="filtraCursosSecretaria"
              >
            </div>
            <div class="col-12 table-responsive mt-2">
              <table class="table table-sm">
                <thead class="thead-dark">
                  <tr class="text-center">
                    <th>
                      <small
                        class="font-weight-bold"
                      >Curso</small>
                    </th>
                    <th>
                      <small
                        class="font-weight-bold"
                      >Publicado</small>
                    </th>
                    <th>
                      <small
                        class="font-weight-bold"
                      >Preço</small>
                    </th>
                    <th class="text-center">
                      <small class="font-weight-bold">Ações</small>
                    </th>
                  </tr>
                </thead>
                <tbody v-if="fastCursosSecretaria.loading">
                  <tr>
                    <td
                      colspan="4"
                      class="text-center"
                    >
                    Carregando...
                    </td>
                  </tr>
                </tbody>
                <tbody v-else-if="fastCursosSecretaria.filtro.length">
                  <tr
                    v-for="e in fastCursosSecretaria.paginacao"
                    :key="e.id_secretaria_curso"
                  >
                    <td class="align-middle">
                      {{ e.nome_curso }}
                    </td>   
                    <td class="align-middle text-center">
                      <span v-if="e.publicado" class="badge badge-success">Sim</span>
                      <span v-else class="badge badge-danger">Não</span>
                    </td>  
                    <td class="align-middle text-center">
                      <span v-if="e.preco">
                        R$ {{ formataPreco(e.preco) }}
                      </span>                      
                    </td>                 
                    <td class="text-center align-middle">                                  
                      <button
                        class="btn btn-sm btn-success pt-0 pb-0 mr-2"
                        @click="adicionaCursoSecretariaComissao(e)"
                      >
                        <small>+ Adicionar</small>
                      </button>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td
                      colspan="4"
                      class="text-center"
                    >
                      Nenhum curso da secretaria encontrado
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-12 text-center mt-4">
              <Pagination
                :page-size="20"
                :items="fastCursosSecretaria.filtro"
                @changePage="fastCursosSecretaria.paginacao = $event"
              />
            </div>
          </div> 
        </div>
      </div>
    </modal>

    <modal
      name="modalListaCursosSecretariaModulos"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-10 pl-4 pr-4">
          <h4>Lista de cursos da secretaria</h4>
        </div>
        <div class="col-2 pl-4 pr-4 text-right">
          <a
            v-if="fastComissaoSchemaDeterminacaoNovo.active"
            class="btn btn-secondary"
            href="#"
            @click.prevent="showModal('modalCriarComissaoDeterminacao', 'modalListaCursosSecretariaModulos')"
          >
            <b-icon-arrow-return-left />
          </a>
          <a
            v-else
            class="btn btn-secondary"
            href="#"
            @click.prevent="showModal('modalEditarComissaoDeterminacao', 'modalListaCursosSecretariaModulos')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-4 mb-1">
              <input
                type="text"
                class="form-control form-control-sm"
                placeholder="Filtrar por nome"
                @keyup="filtraCursosSecretaria"
              >
            </div>
            <div class="col-12 table-responsive mt-2">
              <table class="table table-sm">
                <thead class="thead-dark">
                  <tr class="text-center">
                    <th>
                      <small
                        class="font-weight-bold"
                      >Curso</small>
                    </th>
                    <th>
                      <small
                        class="font-weight-bold"
                      >Publicado</small>
                    </th>
                    <th>
                      <small
                        class="font-weight-bold"
                      >Preço</small>
                    </th>
                    <th class="text-center">
                      <small class="font-weight-bold">Ações</small>
                    </th>
                  </tr>
                </thead>
                <tbody v-if="fastCursosSecretaria.loading">
                  <tr>
                    <td
                      colspan="4"
                      class="text-center"
                    >
                    Carregando...
                    </td>
                  </tr>
                </tbody>
                <tbody v-else-if="fastCursosSecretaria.filtro.length">
                  <tr
                    v-for="e in fastCursosSecretaria.paginacao"
                    :key="e.id_secretaria_curso"
                  >
                    <td class="align-middle">
                      {{ e.nome_curso }}
                    </td>   
                    <td class="align-middle text-center">
                      <span v-if="e.publicado" class="badge badge-success">Sim</span>
                      <span v-else class="badge badge-danger">Não</span>
                    </td>  
                    <td class="align-middle text-center">
                      <span v-if="e.preco">
                        R$ {{ formataPreco(e.preco) }}
                      </span>                      
                    </td>                 
                    <td class="text-center align-middle">                                  
                      <button
                        class="btn btn-sm btn-primary pt-0 pb-0 mr-2"
                        @click="getCursoModulos(e)"
                      >
                        <small>Buscar módulos</small>
                      </button>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td
                      colspan="4"
                      class="text-center"
                    >
                      Nenhum curso da secretaria encontrado
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-12 text-center mt-4">
              <Pagination
                :page-size="20"
                :items="fastCursosSecretaria.filtro"
                @changePage="fastCursosSecretaria.paginacao = $event"
              />
            </div>
          </div> 
        </div>
      </div>
    </modal>

    <modal
      name="modalListaCursosSecretariaUnidades"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-10 pl-4 pr-4">
          <h4>Lista de cursos da secretaria</h4>
        </div>
        <div class="col-2 pl-4 pr-4 text-right">
          <a
            v-if="fastComissaoSchemaDeterminacaoNovo.active"
            class="btn btn-secondary"
            href="#"
            @click.prevent="showModal('modalCriarComissaoDeterminacao', 'modalListaCursosSecretariaUnidades')"
          >
            <b-icon-arrow-return-left />
          </a>
          <a
            v-else
            class="btn btn-secondary"
            href="#"
            @click.prevent="showModal('modalEditarComissaoDeterminacao', 'modalListaCursosSecretariaUnidades')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-4 mb-1">
              <input
                type="text"
                class="form-control form-control-sm"
                placeholder="Filtrar por nome"
                @keyup="filtraCursosSecretaria"
              >
            </div>
            <div class="col-12 table-responsive mt-2">
              <table class="table table-sm">
                <thead class="thead-dark">
                  <tr class="text-center">
                    <th>
                      <small
                        class="font-weight-bold"
                      >Curso</small>
                    </th>
                    <th>
                      <small
                        class="font-weight-bold"
                      >Publicado</small>
                    </th>
                    <th>
                      <small
                        class="font-weight-bold"
                      >Preço</small>
                    </th>
                    <th class="text-center">
                      <small class="font-weight-bold">Ações</small>
                    </th>
                  </tr>
                </thead>
                <tbody v-if="fastCursosSecretaria.loading">
                  <tr>
                    <td
                      colspan="4"
                      class="text-center"
                    >
                    Carregando...
                    </td>
                  </tr>
                </tbody>
                <tbody v-else-if="fastCursosSecretaria.filtro.length">
                  <tr
                    v-for="e in fastCursosSecretaria.paginacao"
                    :key="e.id_secretaria_curso"
                  >
                    <td class="align-middle">
                      {{ e.nome_curso }}
                    </td>   
                    <td class="align-middle text-center">
                      <span v-if="e.publicado" class="badge badge-success">Sim</span>
                      <span v-else class="badge badge-danger">Não</span>
                    </td>  
                    <td class="align-middle text-center">
                      <span v-if="e.preco">
                        R$ {{ formataPreco(e.preco) }}
                      </span>                      
                    </td>                 
                    <td class="text-center align-middle">                                  
                      <button
                        class="btn btn-sm btn-primary pt-0 pb-0 mr-2"
                        @click="getCursoModulos(e)"
                      >
                        <small>Buscar módulos</small>
                      </button>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td
                      colspan="4"
                      class="text-center"
                    >
                      Nenhum curso da secretaria encontrado
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-12 text-center mt-4">
              <Pagination
                :page-size="20"
                :items="fastCursosSecretaria.filtro"
                @changePage="fastCursosSecretaria.paginacao = $event"
              />
            </div>
          </div> 
        </div>
      </div>
    </modal>

    <!-- #endregion -->

  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";
import Pagination from "../components/Pagination";

export default {
  name: "HomeInternoTesourariaComissoes",
  components: {
    Pagination
  },
  mixins: [methods],
  data: function () {
    return {
      //#region variáveis gerais
      modalWidthSm: this.isMobile() ? "80%" : "30%",
      modalWidthMd: this.isMobile() ? "90%" : "50%",
      modalWidthLg: this.isMobile() ? "100%" : "90%", 
      fastNavegacao: {
        exibirComissaoSchemas: true,
        novaComissaoSchema: false,
        editarComissaoSchema: false,
      },
      //#endregion
      //#region variáveis das comissões
      fastComissaoSchema: {
        total: [],
        filtro: [],
        paginacao: [],
        loading: true
      },
      fastComissaoSchemaNovo: {
        id_comissao_schema: 0,
        id_plataforma: this.$route.params.id_plataforma,
        nome_schema_comissao: "",
        todas_plataformas: false,
        desativado: false,
        determinacoes: [],
        loading: false
      },
      fastComissaoSchemaEditar: {
        id_comissao_schema: 0,
        id_plataforma: this.$route.params.id_plataforma,
        nome_schema_comissao: "",
        todas_plataformas: false,
        desativado: false,
        determinacoes: [],
        determinacoesLoading: false,
        loading: false
      },
      fastComissaoSchemaDeterminacaoNovo: {
        id_determinacao: 0,
        id_comissao_schema: 0,
        id_pessoa: 0,
        id_funcao_pessoa: 0,
        id_curso: 0,
        id_secretaria_curso: 0,
        id_secretaria_curso_programa: 0,
        id_secretaria_curso_programa_unidade: 0,
        percentual: 0,
        valor: 0,
        id_plataforma: this.$route.params.id_plataforma,
        nome_razao: "",
        sobrenome_fantasia: "",
        email: "",
        nome_funcao_pessoa: "",
        nome_curso: "",
        nome_curso_secretaria: "",
        sigla_programa: "",
        nome_curso_unidade: "",
        active: false,
        loading: false
      },
      fastComissaoSchemaDeterminacaoEditar: {
        id_determinacao: 0,
        id_comissao_schema: 0,
        id_pessoa: 0,
        id_funcao_pessoa: 0,
        id_curso: 0,
        id_secretaria_curso: 0,
        id_secretaria_curso_programa: 0,
        id_secretaria_curso_programa_unidade: 0,
        percentual: 0,
        valor: 0,
        id_plataforma: this.$route.params.id_plataforma,
        nome_razao: "",
        sobrenome_fantasia: "",
        email: "",
        nome_funcao_pessoa: "",
        nome_curso: "",
        nome_curso_secretaria: "",
        sigla_programa: "",
        nome_curso_unidade: "",
        active: false,
        loading: false
      },
      // Lista de pessoas
      fastPessoasPlataforma: {
        total: [],
        filtro: [],
        paginacao: [],
        filtroParametros: {
          nome_email: {
            ativo: false,
            valor: 0
          },
          perfil: {
            ativo: false,
            valor: 0
          }
        },
        loading: true
      },
      // Funções
      fastFuncaoPessoa: {
        total: [],
        filtro: [],
        paginacao: [],
        loading: true
      },
      // Cursos livres
      fastCursosLivres: {
        total: [],
        filtro: [],
        paginacao: [],
        loading: true
      },
      // Cursos secretaria
      fastCursosSecretaria: {
        total: [],
        filtro: [],
        paginacao: [],
        loading: true
      }
      //#endregion
    };
  },
  mounted: function () {
    // Captura ID da plataforma pela URL
    if (this.$route.params.id_plataforma) {
      this.getFastDependecias(this.$route.params.id_plataforma, 'tesouraria')
        .then(() => {
          if (this.$store.state.fastPlataformaModulos.modulo_secretaria) {
            this.getComissaoSchemas(this.$route.params.id_plataforma)
            this.getUsuariosPlataforma(this.$route.params.id_plataforma)
            this.getFuncaoPessoa(this.$route.params.id_plataforma)
            this.getCursosLivres(this.$route.params.id_plataforma)
            this.getCursosSecretaria(this.$route.params.id_plataforma)
            this.$store.state.fastCarregando = false;
          } else {
            this.$store.state.fastCarregando = false;
            this.exibeToasty("Módulo não disponível para sua plataforma", "error");
          }
        })
        .catch((e) => {
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
        });
    }
  },
  methods: {
    //#region Comissões
    async getComissaoSchemas(id_plataforma){      
      this.fastComissaoSchema.loading = true
      this.promiseGetFastApi("api/fast_tesouraria_comissao_schema/lista", "id_plataforma=" + id_plataforma).then(res => {
        if (res.length) {
          this.fastComissaoSchema.total = res
          this.fastComissaoSchema.filtro = res
        } else {
          this.fastComissaoSchema.total = []
          this.fastComissaoSchema.filtro = []
        }
        this.fastComissaoSchema.loading = false
      }).catch(e => {
        console.log(e)
        this.exibeToasty(e, "error")
        this.fastComissaoSchema.loading = false
      })
    },
    exibeModalNovaComissaoSchema(){
      this.limpaPropsObj(this.fastComissaoSchemaNovo)
      this.alteraFastNavegacao('novaComissaoSchema')
    },
    async crianovaComissaoSchema(){
      let erros = []
      this.fastComissaoSchemaNovo.id_plataforma = this.$route.params.id_plataforma
      if (!this.fastComissaoSchemaNovo.nome_schema_comissao) erros.push("O nome do schema é obrigatório")  
      if (!this.fastComissaoSchemaNovo.determinacoes.length) erros.push("Adicione ao menos um item de comissão")    
      
      if (erros.length) {
        erros.forEach(e => this.exibeToasty(e, "error"))
      } else {        
        this.fastComissaoSchemaNovo.loading = true
        this.promisePostFastApi(this.fastComissaoSchemaNovo, "api/fast_tesouraria_comissao_schema/insere")
        .then((res) => {
          if (res.length) {                        
            this.fastComissaoSchema.total.push(res[0])
            if (this.fastComissaoSchemaNovo.determinacoes.length) {           
              this.fastComissaoSchemaNovo.determinacoes.forEach((e, index) => {                
                e.id_comissao_schema = res[0].id_comissao_schema
                e.id_plataforma = this.$route.params.id_plataforma 

                this.promisePostFastApi(e, "api/fast_tesouraria_comissao_schema_determinacao/insere")
                .then(res2 => {
                  console.log("api/fast_tesouraria_comissao_schema_determinacao/insere", res2)
                  if (this.fastComissaoSchemaNovo.determinacoes.length == (index + 1)) {
                    this.getComissaoSchemas(this.$route.params.id_plataforma)
                    this.alteraFastNavegacao('exibirComissaoSchemas')
                    this.exibeToasty("Schema criado com sucesso", "success")   
                  }
                }).catch((e) => {
                  this.exibeToasty("Erro ao adicionar item " + (index + 1), "error")
                }); 
              })
            } else {
              this.alteraFastNavegacao('exibirComissaoSchemas')
              this.exibeToasty("Schema criado com sucesso", "success")              
            }      
            
          } else {
            this.exibeToasty("Erro ao criar schema", "error")
            this.fastComissaoSchemaNovo.loading = false
            this.limpaPropsObj(this.fastComissaoSchemaNovo)
          }         
        }).catch((e) => {
          this.exibeToasty("Erro ao criar schema", "error")
          this.fastComissaoSchemaNovo.loading = false
        });           
      }   
     
    },
    exibeModaleditarComissaoSchema(e) {
      console.log("exibeModaleditarComissaoSchema(e)", e)
      e.loading = false
      e.determinacoes = []
      e.determinacoesLoading = true
      this.fastComissaoSchemaEditar = e

      this.fastComissaoSchemaDeterminacaoNovo.id_comissao_schema = this.fastComissaoSchemaEditar.id_comissao_schema

      this.promiseGetFastApi("api/fast_tesouraria_comissao_schema_determinacao/lista", "id_plataforma=" + this.fastComissaoSchemaEditar.id_plataforma + "&id_comissao_schema=" + this.fastComissaoSchemaEditar.id_comissao_schema)
      .then(res => {
        if (res.length) this.fastComissaoSchemaEditar.determinacoes = res
        this.fastComissaoSchemaEditar.determinacoesLoading = false
        this.alteraFastNavegacao('editarComissaoSchema')
      }).catch(e => {
        console.log(e)
        this.exibeToasty(e, "error")
        this.fastComissaoSchemaEditar.determinacoesLoading = false
      })
    },
    exibeModalEditarComissaoDeterminacao(){
      this.limpaPropsObj(this.fastComissaoSchemaDeterminacaoNovo)
      this.fastComissaoSchemaDeterminacaoNovo.active = false 
      this.fastComissaoSchemaDeterminacaoEditar.active = true           
      this.showModal('modalCriarComissaoDeterminacao')
    },
    async editaComissaoSchema(){
      let erros = []
      this.fastComissaoSchemaEditar.id_plataforma = this.$route.params.id_plataforma
      if (!this.fastComissaoSchemaEditar.nome_schema_comissao) erros.push("O nome do schema é obrigatório")
      
      if (erros.length) {
        erros.forEach(e => this.exibeToasty(e, "error"))
      } else {
        this.fastComissaoSchemaEditar.loading = true
        this.promisePostFastApi(this.fastComissaoSchemaEditar, "api/fast_tesouraria_comissao_schema/atualiza")
        .then((res) => {
          if (res.length) {            
            //this.fastComissaoSchema.total.forEach(e => { if (e.id_comissao_schema == this.fastComissaoSchemaEditar.id_comissao_schema) e = res[0] })
            this.getComissaoSchemas(this.$route.params.id_plataforma)
            this.alteraFastNavegacao('exibirComissaoSchemas')
            this.exibeToasty("Alterações salvas com sucesso", "success")
            this.limpaPropsObj(this.fastComissaoSchemaEditar)
          } else {
            this.exibeToasty("Erro ao salvar alteração", "error")
            this.fastComissaoSchemaEditar.loading = false
          }         
        }).catch((e) => {
          this.exibeToasty("Erro ao salvar alteração", "error")
          this.fastComissaoSchemaEditar.loading = false
        });
      }    
    },
    exibeModalExcluirFuncao(e) {
      e.loading = false
      this.fastComissaoSchemaEditar = e
      this.showModal('modalExcluirSchemaComissao')
    },
    async excluiComissaoSchema(){
      let erros = []
      this.fastComissaoSchemaEditar.id_plataforma = this.$route.params.id_plataforma
      
      if (erros.length) {
        erros.forEach(e => this.exibeToasty(e, "error"))
      } else {
        this.fastComissaoSchemaEditar.loading = true
        this.promisePostFastApi(this.fastComissaoSchemaEditar, "api/fast_tesouraria_comissao_schema/exclui")
        .then((res) => {
          this.fastComissaoSchema.total = res
          this.fastComissaoSchema.filtro = res

          this.limpaPropsObj(this.fastComissaoSchemaEditar)
          this.alteraFastNavegacao('exibirComissaoSchemas')
          this.exibeToasty("Schema excluído com sucesso", "success")
          this.hideModal('modalExcluirSchemaComissao')  
        }).catch((e) => {
          console.log(e)
          this.exibeToasty("Erro ao excluir schema", "error")
          this.fastComissaoSchemaEditar.loading = false
        });
      }    
    },
    //#endregion
    //#region Pessoas
    async getUsuariosPlataforma(id_plataforma){
      this.fastPessoasPlataforma.loading = true
      let param = `id_pessoa=0&id_plataforma=${id_plataforma}`

      this.promiseGetFastApi("api/fast_tesouraria_funcao_pessoa/lista_pessoas", param).then(obj => {
        console.log("getUsuariosPlataforma", obj)
        if (obj.length) {
          this.fastPessoasPlataforma.total = obj
          this.fastPessoasPlataforma.filtro = obj
        }
        else {
          this.fastPessoasPlataforma.total = []
          this.fastPessoasPlataforma.filtro = []
        }          
        this.fastPessoasPlataforma.loading = false  
      }).catch(e => {         
        this.exibeToasty("Erro ao buscar alunos", "error");
        this.fastPessoasPlataforma.loading = false
      })
    },
    filtraPessoas(e) {
      console.log("this.fastPessoasPlataforma.filtroParametros", this.fastPessoasPlataforma.filtroParametros)

      if (this.fastPessoasPlataforma.filtroParametros.nome_email.valor.length > 2) this.fastPessoasPlataforma.filtroParametros.nome_email.ativo = true
      else
        this.fastPessoasPlataforma.filtroParametros.nome_email.ativo = false

      if (this.fastPessoasPlataforma.filtroParametros.perfil.valor) this.fastPessoasPlataforma.filtroParametros.perfil.ativo = true
      else
        this.fastPessoasPlataforma.filtroParametros.perfil.ativo = false

      

      if (!this.fastPessoasPlataforma.filtroParametros.nome_email.ativo && !this.fastPessoasPlataforma.filtroParametros.perfil.ativo) {
        this.fastPessoasPlataforma.filtro = this.fastPessoasPlataforma.total
      } else {
        if (this.fastPessoasPlataforma.filtroParametros.nome_email.ativo) {
          this.fastPessoasPlataforma.filtro = this.fastPessoasPlataforma.total.filter((e) => {
            return e.first_name.toLowerCase().indexOf(this.fastPessoasPlataforma.filtroParametros.nome_email.valor.toLowerCase()) != "-1" || e.last_name.toLowerCase().indexOf(this.fastPessoasPlataforma.filtroParametros.nome_email.valor.toLowerCase()) != "-1" || e.email.toLowerCase().indexOf(this.fastPessoasPlataforma.filtroParametros.nome_email.valor.toLowerCase()) != "-1";
          });
        } else {
          this.fastPessoasPlataforma.filtro = this.fastPessoasPlataforma.total
        }

        if (this.fastPessoasPlataforma.filtroParametros.perfil.ativo) {
          switch (this.fastPessoasPlataforma.filtroParametros.perfil.valor) {
            case "administrador":
              this.fastPessoasPlataforma.filtro = this.fastPessoasPlataforma.filtro.filter((e) => {
                return e.administrador == "S"
              });
              break;
            case "aluno":
              this.fastPessoasPlataforma.filtro = this.fastPessoasPlataforma.filtro.filter((e) => {
                return e.aluno == "S"
              });
              break;
            case "professor":
              this.fastPessoasPlataforma.filtro = this.fastPessoasPlataforma.filtro.filter((e) => {
                return e.professor == "S"
              });
              break;
            case "secretaria":
              this.fastPessoasPlataforma.filtro = this.fastPessoasPlataforma.filtro.filter((e) => {
                return e.secretario == "S"
              });
              break;
            case "tesouraria":
              this.fastPessoasPlataforma.filtro = this.fastPessoasPlataforma.filtro.filter((e) => {
                return e.tesouraria == "S"
              });
              break;
            case "responsavel":
              this.fastPessoasPlataforma.filtro = this.fastPessoasPlataforma.filtro.filter((e) => {
                return e.responsavel == "S"
              });
              break;
          
            default:
              break;
          }
        }
      }

    },
    exibeModalCriarComissaoDeterminacao(){
      this.limpaPropsObj(this.fastComissaoSchemaDeterminacaoNovo)
      this.fastComissaoSchemaDeterminacaoEditar.active = false
      this.fastComissaoSchemaDeterminacaoNovo.active = true            
      this.showModal('modalCriarComissaoDeterminacao')
    },
    adicionaPessoaComissao(obj) {
      this.fastComissaoSchemaDeterminacaoNovo.id_pessoa = obj.id_pessoa
      this.fastComissaoSchemaDeterminacaoNovo.nome_razao = obj.nome_razao
      this.fastComissaoSchemaDeterminacaoNovo.sobrenome_fantasia = obj.sobrenome_fantasia
      this.fastComissaoSchemaDeterminacaoNovo.email = obj.email
      this.showModal('modalCriarComissaoDeterminacao', 'modalListaPessoas')
      /*
      if (this.fastComissaoSchemaDeterminacaoNovo.active) {
        this.fastComissaoSchemaDeterminacaoNovo.id_pessoa = obj.id_pessoa
        this.fastComissaoSchemaDeterminacaoNovo.nome_razao = obj.nome_razao
        this.fastComissaoSchemaDeterminacaoNovo.sobrenome_fantasia = obj.sobrenome_fantasia
        this.fastComissaoSchemaDeterminacaoNovo.email = obj.email
        this.showModal('modalCriarComissaoDeterminacao', 'modalListaPessoas')
      } else {
        this.fastComissaoSchemaDeterminacaoNovo.id_pessoa = obj.id_pessoa
        this.fastComissaoSchemaDeterminacaoNovo.nome_razao = obj.nome_razao
        this.fastComissaoSchemaDeterminacaoNovo.sobrenome_fantasia = obj.sobrenome_fantasia
        this.fastComissaoSchemaDeterminacaoNovo.email = obj.email
        this.showModal('modalCriarComissaoDeterminacao', 'modalListaPessoas')
      }
      */
    },
    //#endregion
    //#region Funções
    async getFuncaoPessoa(id_plataforma){      
      this.fastFuncaoPessoa.loading = true
      this.promiseGetFastApi("api/fast_tesouraria_funcao_pessoa/lista", "id_plataforma=" + id_plataforma).then(res => {
        console.log("getFuncaoPessoa", res)
        if (res.length) {
          this.fastFuncaoPessoa.total = res
          this.fastFuncaoPessoa.filtro = res
        }
        else {
          this.fastFuncaoPessoa.total = []
          this.fastFuncaoPessoa.filtro = []
        }

        this.fastFuncaoPessoa.loading = false
      }).catch(e => {
        console.log(e)
        this.exibeToasty(e, "error")
        this.fastFuncaoPessoa.loading = false
      })
    },
    adicionaFuncaoPessoaComissao(obj){
      this.fastComissaoSchemaDeterminacaoNovo.id_funcao_pessoa = obj.id_funcao_pessoa
      this.fastComissaoSchemaDeterminacaoNovo.nome_funcao_pessoa = obj.nome_funcao_pessoa
      this.showModal('modalCriarComissaoDeterminacao', 'modalListaFuncoesPessoa')
    },
    //#endregion
    //#region Cursos livres
    async getCursosLivres(id_plataforma){   
      this.fastCursosLivres.loading = true
      this.promiseGetFastApi("api/fast_curso_plataforma", "id_usuario=0&id_plataforma=" + id_plataforma + "&id_curso=").then(res => {
        console.log("getCursosLivres", res)
        if (res.length) {
          this.fastCursosLivres.total = res
          this.fastCursosLivres.filtro = res
        }
        else {
          this.fastCursosLivres.total = []
          this.fastCursosLivres.filtro = []
        }

        this.fastCursosLivres.loading = false
      }).catch(e => {
        console.log(e)
        this.exibeToasty(e, "error")
        this.fastCursosLivres.loading = false
      })
    },
    filtraCursosLivres(e) {
      let nome_curso_busca = e.target.value
      if (nome_curso_busca.length > 2) {
        this.fastCursosLivres.filtro = this.fastCursosLivres.total.filter((e) => {
          if (!e.nome_curso) e.nome_curso = e.nome_curso_mae
          return e.nome_curso.toLowerCase().indexOf(nome_curso_busca.toLowerCase()) != "-1" 
        });
      } else {
        this.fastCursosLivres.filtro = this.fastCursosLivres.total
      }      
    },
    adicionaCursoLivreComissao(obj){
      this.fastComissaoSchemaDeterminacaoNovo.id_curso = obj.id_curso
      this.fastComissaoSchemaDeterminacaoNovo.nome_curso = obj.nome_curso ? obj.nome_curso : obj.nome_curso_mae
      this.showModal('modalCriarComissaoDeterminacao', 'modalListaCursosLivres')
    },
    //#endregion
    //#region Cursos da secretario
    async getCursosSecretaria(id_plataforma){   
      this.fastCursosSecretaria.loading = true
      this.promiseGetFastApi("api/fast_secretaria_curso/lista", "id_plataforma=" + id_plataforma + "&id_secretaria_curso=0").then(res => {
        console.log("getCursosSecretaria", res)
        if (res.length) {
          this.fastCursosSecretaria.total = res
          this.fastCursosSecretaria.filtro = res
        }
        else {
          this.fastCursosSecretaria.total = []
          this.fastCursosSecretaria.filtro = []
        }

        this.fastCursosSecretaria.loading = false
      }).catch(e => {
        console.log(e)
        this.exibeToasty(e, "error")
        this.fastCursosSecretaria.loading = false
      })
    },
    filtraCursosSecretaria(e) {
      let nome_curso_busca = e.target.value
      if (nome_curso_busca.length > 2) {
        this.fastCursosSecretaria.filtro = this.fastCursosSecretaria.total.filter((e) => {
          if (!e.nome_curso) e.nome_curso = e.nome_curso_mae
          return e.nome_curso.toLowerCase().indexOf(nome_curso_busca.toLowerCase()) != "-1" 
        });
      } else {
        this.fastCursosSecretaria.filtro = this.fastCursosSecretaria.total
      }      
    },
    adicionaCursoSecretariaComissao(obj){
      this.fastComissaoSchemaDeterminacaoNovo.id_secretaria_curso = obj.id_secretaria_curso
      this.fastComissaoSchemaDeterminacaoNovo.nome_curso_secretaria = obj.nome_curso 
      this.showModal('modalCriarComissaoDeterminacao', 'modalListaCursosSecretaria')
    },
    //#endregion
    //#region Confirma item comissão
    confirmaItemComissao(){
      if (this.fastComissaoSchemaDeterminacaoNovo.active) {

        // Impede atribuição por referência
        const obj = Object.assign({}, this.fastComissaoSchemaDeterminacaoNovo);
        this.fastComissaoSchemaNovo.determinacoes.push(obj)

        this.hideModal('modalCriarComissaoDeterminacao')
      } else {        
        console.log("this.fastComissaoSchemaDeterminacaoNovo", this.fastComissaoSchemaDeterminacaoNovo)

        this.fastComissaoSchemaDeterminacaoNovo.id_comissao_schema = this.fastComissaoSchemaEditar.id_comissao_schema
        this.fastComissaoSchemaDeterminacaoNovo.id_plataforma = this.fastComissaoSchemaEditar.id_plataforma
        
        this.promisePostFastApi(this.fastComissaoSchemaDeterminacaoNovo, "api/fast_tesouraria_comissao_schema_determinacao/insere")
        .then(res => {
          console.log("api/fast_tesouraria_comissao_schema_determinacao/insere", res)
          if (res.length) {
            this.getComissaoSchemas(this.$route.params.id_plataforma)
            this.hideModal('modalCriarComissaoDeterminacao')
            this.exibeToasty("Schema criado com sucesso", "success")   
            this.fastComissaoSchemaEditar.determinacoes.push(res[0])
            this.limpaPropsObj(this.fastComissaoSchemaDeterminacaoNovo) 
            
          } else {
            this.exibeToasty("Erro ao adicionar item", "error")
          }          
        }).catch((e) => {
          this.exibeToasty(e, "error")
        })
      }      
    },
    removerItemComissaoNovo(index){
        this.fastComissaoSchemaNovo.determinacoes = this.fastComissaoSchemaNovo.determinacoes.filter((item, i) => i !== index)
    },
    removerItemComissaoEditar(obj){  
      console.log("this.fastComissaoSchemaEditar", this.fastComissaoSchemaEditar)
      console.log("removerItemComissaoEditar(obj)", obj)
      this.fastComissaoSchemaEditar.determinacoesLoading = true
      this.fastComissaoSchemaEditar.determinacoes = []
      this.promisePostFastApi(obj, "api/fast_tesouraria_comissao_schema_determinacao/exclui")
      .then((res) => {
        this.getComissaoSchemas(this.$route.params.id_plataforma)
        this.exibeModaleditarComissaoSchema(this.fastComissaoSchemaEditar)        
        this.fastComissaoSchemaEditar.determinacoesLoading = false            
      }).catch((e) => {
        console.log(e)
        this.exibeToasty("Erro ao excluir schema", "error")
        this.fastComissaoSchemaEditar.determinacoesLoading = false
      })
    },
    //#endregion
  },
};
</script>

<style></style>
